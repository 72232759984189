.pmenu{
    display: flex;
    justify-content: space-between;
}
.pmenu input{
    height: 5vh;
    border-radius: 5px;
}

  .dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
  }
  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  }
  .catbtn{
    background: rgb(255, 255, 255)  !important;
  }
  /* .catbtn:hover{
    background: beige;
  } */
  .show li button {
    font-size: 14px;
    color: #686161;
  font-weight: 300;
  font-family: Helvetica;
  }
  .p{
    margin-top: 16vh;
    color: #dddddd;
  }
  .allbtn{
    background: none;
    border: none;
    margin-left: 10px;
    color: #686161;
  }

  .dropdown-menu .dropdown-submenu {
    display: none;
   
    left: 100%;
    top: 30px;
    height: 28vh;
}