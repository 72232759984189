.button {
    width: 300px;
    text-align: center;
}
.img {
    height: 50vh;
    width: 300px;
    margin-right: 30px;
    border-radius: 10px;
}
.createImg {
    float: right;
    /* margin-right: 30px; */
    border-radius: 10px;
    /* height: 100%; */
    /* width: 100%; */
  }
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.8);  */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure the loader appears above all other content */
  }
  
