
table {
      text-align: center;
      
    width:100%;
  }

  .table, th, td {
    border: 1px solid transparent;
    border-collapse: collapse;
  
}

@import url("https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css");
body{margin-top:20px;}
.steps .step {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    text-align: center
}
.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    text-align: center
}
.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 2px;
    margin-top: -10px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}
.steps .step .step-icon-wrap::before {
    left: 0
}
.steps .step .step-icon-wrap::after {
    right: 0
}
.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 30px;
    line-height: 65px;
    z-index: 5
}
.steps .step .step-title {
    margin-top: 16px;
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}
.steps .step:first-child .step-icon-wrap::before {
    display: none
}
.steps .step:last-child .step-icon-wrap::after {
    display: none
}
.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: rgb(161, 161, 161)
}
.icon1 {
  border-color: #172738;
  background-color: #132231;
  color: #fff
}
@media (max-width: 576px) {
    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: none
    }
}
@media (max-width: 768px) {
    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
}
@media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }
}
@media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}
.bg-faded, .bg-secondary {
    background-color: #f5f5f5 !important;
}