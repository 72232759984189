#card{
  border-radius: 2px;
  transition: all 0.25s linear;
  box-shadow: 0px 1px 2px 0px rgba(34, 33, 33, 0.4);
}

#card:hover {
  box-shadow: -1px 10px 29px 0px rgba(255, 252, 252, 0.8);
}
.capital{
  text-transform: capitalize;
}
.MuiCard-root {
  overflow: hidden;
  background: transparent;
}

.ProductItemImageSquare img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image fills the square without stretching */
}

.customCard .slick-slide {
  /* Your CSS styles for .slick-slide here */
  overflow: hidden;
  padding-right: 15px;
  position: relative;
  width: 175px !important;
}

.slick-initialized .slick-next {
  margin-right: 2%;
}

.slick-initialized .slick-prev {
  margin-left: 2%;
}

.slick-initialized button.slick-prev {
  /* Your styles for .slick-prev buttons here */
 /*  background: rgb(64, 255, 5); */
  margin-left: 0%;
}

.slick-initialized button.slick-next {
  /* Your styles for .slick-prev buttons here */
/*   background: rgb(64, 255, 5); */
  margin-right: 0%;
}

.hovered-dropdown {
  transition: background 0.3s ease; /* Add a smooth transition effect on the background property */
}

.hovered-dropdown:hover {
  background: #4ce1b6;
}

.hovered-dropdown1 {
  transition: background 0.3s ease; /* Add a smooth transition effect on the background property */
}

.hovered-dropdown1:hover {
  background: #70f3ce;
}

.navbar-toggler-icon {
  border: 2px solid #5f5f5f; /* Set the desired border color */
  border-radius: 5px;
  padding: 15px;
}

