.feature label{
color: white;
}
.feature table{
    color: white;
    border:1px solid white;
}

.feature table, th, td {
    color: white;

}

.custom-multi-select {
    background-color: white;
  }
  .featureName{
    height: 6vh;
    border-radius: 5px;
    width: 70%;
  }

  .AddFeature{
    margin-left: 2rem;
    height: 7vh;
    border-radius: 5px;
    background: cadetblue;
  }

  .subfeature{
    margin-top: 1rem;
    width: 70%;
    height: 6vh;
    display: flex;
    justify-content: space-between;
    background: white;
    border-radius: 5px;
  }

  .MuiSelect-select.MuiSelect-select {
    margin-bottom: -6px;
    font-size: small;
  }