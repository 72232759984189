/* CSS for Chat Button */
.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  /* CSS for Chat Box */
  .chat-box {
    position: fixed;
    bottom: 40px;
    right: 10px;
    width: 400px;
    height: 70%;
    z-index: 999;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    display: none;
  }

  .chat-box1 {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: auto;
    height: auto;
    background-color: #fff;
    z-index: 999;
    /* border: 1px solid #ccc; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    display: none;
  }
  
  .chat-box.open {
    display: block;
  }

  .chat-box1.open {
    display: block;
  } 

  .button-hover {
    transition: background-color 0.3s; /* Optional: Add a smooth transition effect */
  
    /* Define the default background color */
    background-color: #4ce1b6;
  
    /* Define the background color on hover */
    &:hover {
      background-color: #232329 /* Change this to your desired hover background color */
    }
  }

  iframe#webpack-dev-server-client-overlay{display:none!important}
  