
  
  .title {
    margin-bottom: 50px;
    text-transform: uppercase;
  }
  .c1{
    background-color: #2a2a31;
    border: none !important;
    box-shadow: none !important;
  }
  .card-block {
    font-size: 1rem;
    position: relative;
    margin: 0;
    padding: 1rem;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    box-shadow: none;
  }
  
  .card {
    font-size: 1rem;
    overflow: hidden;
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
   
    /* margin-top: 20px; */
  }
  
  .carousel-indicators li {
    border-radius: 12px;
    width: 12px;
    height: 12px;
    background-color: #404040;
  }
  
  .carousel-indicators .active {
    background-color: white;
    max-width: 12px;
    margin: 0 3px;
    height: 12px;
  }
  
  .btn {
    margin-top: auto;
  }
  

  
  
  

  .word-space {
    margin-left: 15px;
    font-size: larger;
    font-weight: bolder;
    /* font-style: italic; */
  }
  .word-space1 {
    margin-left: 20px;
    font-size: larger;
    font-weight: bolder;
    /* font-style: italic; */
  }
  /* .cam{
margin-top: 6rem;
  } */
  .cam:hover{
cursor: pointer;
  }
  .cf{
    display: flex;
    justify-content: space-between;
  }
  .textAlign{
    text-transform: capitalize;
  }