.main {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* padding: 20px 30px; */
    border-radius: 18px;
    overflow: hidden;
    z-index: 1;
    backface-visibility: hidden;
    clip-path: inset(0); /* Adjust the clipping path as needed */
  }
  
