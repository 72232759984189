.purple-button {
  background-color: purple;
  color: white;
  border: none;
}

  
  .purple-button:hover {
    background-color: #7b68ee; /* Lighter shade of purple on hover */
  }
  body {
    font-family: 'Roboto', sans-serif;
  }
  .btn-get-started:hover{
    text-decoration: none;
    background: #ffffff; 
    color:#5e317e; 
    font-weight: 500;
  }
  .btn-get-started{
    background: #5e317e; 
    font-family: inherit; 
    color:white; 
    font-weight: 500;
    border: none; 
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 10px;
  }
  .containerDiv{
    /* border: 2px solid #dadada;  */
    border-radius: 10px; 
    padding: 2%;
    font-family: system-ui;
  }

.purple-button:hover {
  background-color: #7b68ee; /* Lighter shade of purple on hover */
}
body {
  font-family: "Roboto", sans-serif;
}
.btn-get-started:hover {
  text-decoration: none;
  background: #ffffff;
  color: #5e317e;
  font-weight: 500;
}
.btn-get-started {
  background: #5e317e;
  font-family: inherit;
  color: white;
  font-weight: 500;
  border: none;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
}
.containerDiv {
  /* border: 2px solid #dadada;  */
  border-radius: 10px;
  padding: 2%;
  font-family: system-ui;
}

.container {
  font-family: system-ui;
}

.zoom-in-on-hover {
  transition: transform 0.5s ease-in-out; /* Add a smooth transition effect */
}

.zoom-in-on-hover:hover {
  transform: scale(1.2); /* Zoom in by 10% on hover (adjust as needed) */
}

.productPrice {
  font-family: "Roboto";
  font-weight: 500;
  font-size: small;
  color: white;
}

.productCard:hover .producttitle {
  color: #4ce1b6;
}
.productCard:hover .productPrice {
  color: #4ce1b6;
}

.producttitle {
  font-family: "Roboto";
  color: white;
}

.currentPrice {
  font-weight: 600;
}
.CreatorDiv1 {
  border: 2px solid #dadada;
  border-radius: 10px;
}
.createImg {
  float: right;
  /* margin-right: 30px; */
  border-radius: 10px;
  /* height: 100%; */
  /* width: 100%; */
}

.card {
  height: 90%;

  padding: 15px;
}

.hero-img img {
  border-radius: 10px;
  /* height: 100%; */
  width: 100%;
}
.editSpan {
  float: right;
}
.overlay-text {
  padding: 0px;
  text-align: center;
  position: absolute;
  top: 12px;
  right: 12px;
  background: #5e317e;
  border-radius: 10px;
  opacity: 1;
}

.overlay-text p span {
  font-size: 20px;
  color: rgb(255, 255, 255);
  padding-left: 5px;
  padding-right: 5px;
}

.overlay-text-new {
  padding: 0px;
  text-align: center;
  position: absolute;
  top: 20px;
  right: 20px;
  background: #5e317e;
  border-radius: 10px;
  opacity: 1;
}
.overlay-text-delete {
  padding: 4px;
  text-align: center;
  position: absolute;
  top: 12px;
  right: 50px;
  background: #5e317e;
  border-radius: 10px;
  opacity: 1;
  cursor: pointer;
}

.overlay-text-new label {
  font-size: 20px;
  color: rgb(255, 255, 255);
  padding-left: 5px;
  padding-right: 4px;
  padding-top: 4px;
}

a:hover {
  text-decoration: none;
}

.border-radius {
  border-radius: 12px;
}

.square-img-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 100%; /* Creates a square space */
}

.square-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  object-fit: scale-down; /* Ensure the image covers the square space */
}

.square-img-wrapper1 {
  position: relative;
  overflow: hidden;
  padding-top: 100%; /* Creates a square space */
}
.jzuTEz .slick-slide img {
  min-height: max-content !important;
}

.square-img1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  /* object-fit: scale-down; */
  /* Ensure the image covers the square space */
}

.carousel-container {
  max-width: 800px; /* Set your desired width */
  height: 100%;
  margin: 0 auto;
  padding: 20px;
}

@media (min-width: 769px) {
  .span1 {
    position: absolute;
    bottom: 30%;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    object-fit: scale-down;
     /* Ensure the image covers the square space */
  }

  .span2 {
    float: left;
    position: absolute;
    bottom: 0px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(143, 143, 143);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(106, 106, 106);
  border-radius: 10px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  box-shadow: none;
  text-transform: capitalize;
}

.sidebar-category {
  opacity: 0.2; /* Adjust the opacity value as needed */
}

.nav-item .nav-link {
  width: 100%;
  margin-bottom: 0px;
  color: white;
  font-family: system-ui;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}

.nav-item .nav-link:active {
  width: 100%;
  margin-bottom: 0px;
  color: white;
  font-family: system-ui;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}

.nav-item .nav-link:hover {
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-family: system-ui;
  font-size: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}

.nav-item .nav-link:focus {
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-family: system-ui;
  font-size: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent;
  border: 1px solid #f0f0f0;
  border-bottom: 0.1px solid #2a2a31;
  /* border-bottom: none; */
  color: white;
  text-transform: capitalize;
}

.tab-content > .active {
  display: block;
  color: white;
}
