.star-icon {
    font-size: 24px; /* Adjust the size as needed */
    color: gray; /* Color of empty stars */
    cursor: pointer; /* Make the stars clickable */
    margin-right: 5px; /* Adjust the spacing between stars */
  }
  
  /* CSS for filled star icons */
  .star-filled {
    color: gold; /* Color of filled stars */
  }