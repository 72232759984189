.cr-marquee-label.cr-marquee-label__light {
  display: none;
  height: 48px !important;
  border-radius: 0px !important;
}
.cr-widget-marquee-container {
  border: none !important;
}

.cr-widget-marquee-container__light {
  height: 48px !important;
  border-radius: 0px !important;
}
.cr-widget-marquee-container__light {
  background-color: transparent !important;
}
.cr-marquee-item-coin__column {
  color: white !important;
}
.cr-marquee-item__light:hover {
  background-color: transparent !important;
}
.slick-dots {
  margin-bottom: 10px !important;
}
.slick-list {
  max-height: 100% !important;
}
.profile-avatar:hover img {
  filter: brightness(70%);
}

.edit-icon {
  display: none;
}

.profile-avatar:hover .edit-icon {
  display: block;
}
